import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class About extends React.Component {

	constructor(props){
		super(props)

		this.state = {
			showHiddenText: false
		};

		this.showHiddenText = this.showHiddenText.bind(this);
	}

	showHiddenText(){
		this.setState({showHiddenText: !this.state.showHiddenText});
	}

	render() {

		const showHiddenText = this.state.showHiddenText;
		let buttonIcon;
		let buttonText;

		if (showHiddenText){
			buttonIcon = <FontAwesomeIcon icon={['fas', 'chevron-up']} />
			buttonText = "Lees minder"
		} else {
			buttonIcon = <FontAwesomeIcon icon={['fas', 'chevron-down']} />
			buttonText = "Lees meer"
		}

		return (
			<section id="about">
			<div className="container">
			<div className="aboutText">
				<h1>Over Relentless Grace Radio</h1>
				<p>Met Relentless Grace Radio willen we samen met de mensen in hun huiskamers God loven en prijzen met super te gekke muziek. Muziek die eindeloos met de tijd meegaat! Met onze muziek willen we Gods genade vieren. Zijn Grace is Relentless, wat twee betekenissen kan hebben; Namelijk meedogenloos en tegelijkertijd ook eindeloos.</p>
					<div className={"hiddenText " + (showHiddenText ? 'show' : '')}>
						<p>Stel je eens voor: je wandelt op een smal pad met de zon die behoorlijk heet op je lichaam brandt. Het houdt maar niet op, maar tóch zet je door ondanks de hitte. Zo kun je het woord relentless gebruiken: Meedogenloos en tegelijkertijd eindeloos. En dat is het nu ook met Gods genade!</p>
						<p>Wij als Christenen / discipelen van Jezus, die geroepen zijn om in de Genade te wandelen, stappen soms even zijwaarts af om toch weer in het wettische te vallen, maar dan komt God en leidt ons steeds weer op Zijn pad van Genade. Bij de één gebeurt dat via een preek, bij de ander via het Woord. Bij anderen door visioenen of door een persoonlijk Woord van de Heilige Geest. Hoe dan ook, Hij is constant meedogenloos bezig om ons op het pad van de Genade te houden; Het pad van liefde voor jezelf door Hem en liefde voor anderen, dit allemaal gebeurt door de Heilige Geest.</p>
					</div>
				</div>
				<div className="christian-cross" >
					{/*<img src={musicVisual} />*/}
				</div>

				<div className="button" onClick={this.showHiddenText}>
					<span>{buttonText}</span>
					<span>{buttonIcon}</span>
				</div>
			</div>

		</section>
		)
	}
}

export default About;