import React from 'react';

class Footer extends React.Component {

	render() {
		return (
			<div id="footer">
			<svg className="footer" viewBox="0 0 1024 160">
			    <desc>Created with Sketch.</desc>
			    <defs></defs>
			    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			        <g id="Desktop-Copy" transform="translate(0.000000, -2430.000000)" fill="#000000">
			            <path d="M-109,2590 L1135,2590 C1135,2590 843.138491,2430.15983 513.000756,2430.15983 C182.861509,2430.15983 -109,2590 -109,2590" id="Fill-1"></path>
			        </g>
			    </g>
			</svg>
			
			<footer>
				<div className="container">
					<div className="contact">
						<h1>Contact</h1>
						<span className="name">Chiquita Gravenstijn - Woerdings</span>
						<span className="email"><a href="mailto:relentlessgraceradio@hotmail.com">relentlessgraceradio@hotmail.com</a></span>
					</div>
						
					<div className="copyright">
						<span>&#9400; Relentless Grace Radio {new Date().getFullYear()}</span>
					</div>
				</div>	
			</footer>
		</div>
		)
	}
}

export default Footer;