import React from 'react';

class Encouragement extends React.Component {
	constructor (props) {
		super(props);
	}

	render() {
		return (
			<section id="bibleText" >
				<h1>Bemoediging</h1>
				<div className="encouragement">
					<p>{this.props.data.encouragement_text}</p>
					<span className="bibleVerse">{this.props.data.encouragement_scripture}</span>
					{/*<p>Hierin is de liefde bij ons volmaakt geworden, dat wij vrijmoedigheid hebben op de dag des oordeels, want gelijk Hij is, zijn ook wij in deze wereld. Er is in de liefde geen vrees, maar de volmaakte liefde drijft de vrees uit; want de vrees houdt verband met straf en wie vreest, is niet volmaakt in de liefde.</p>
					<span className="bibleVerse">1 Johannes 4 : 17-18</span>*/}

				</div>
			</section>
		)
	}
}

export default Encouragement;