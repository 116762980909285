import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Top5 extends React.Component {

	constructor(props){
		super(props)
	}

	state = {
		pattern: new RegExp('^(https?:\\/\\/)?'+ // protocol
		    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
		    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
		    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
		    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
		    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
	};

	handleResize(){
		if (window.innerWidth >= 600 ) {
			let allRankNumbers = document.querySelectorAll("section#topSongs div.ranking ul li span.rank")
			let allSongInfo = document.querySelectorAll("section#topSongs div.ranking ul li div.songInfo")
			let allOverlays = document.querySelectorAll("section#topSongs div.ranking ul li div.overlay")
			let allSvgs = document.querySelectorAll("section#topSongs div.ranking ul li div.overlay svg.arrow")

			for (var i = allRankNumbers.length - 1; i >= 0; i--) {
				allRankNumbers[i].classList.remove("fadeOut");
			}

			for (var i = allSongInfo.length - 1; i >= 0; i--) {
				allSongInfo[i].classList.remove("fadeOut");
			}

			for (var i = allOverlays.length - 1; i >= 0; i--) {
				allOverlays[i].classList.remove("open");
				allOverlays[i].setAttribute("open", 0);
			}

			for (var i = allSvgs.length - 1; i >= 0; i--) {
				allSvgs[i].classList.remove("rotate")
			}
		}
	}

	componentDidMount(){ 
		let elements = document.querySelectorAll("section#topSongs div.ranking ul li div.overlay");

		for (var i = elements.length - 1; i >= 0; i--) {
			elements[i].setAttribute("child-index", i);
			elements[i].setAttribute("open", 0);
		}

		window.addEventListener('resize', this.handleResize);

		//console.log(elements);
	}

	checkYoutubeSongLink(number){
		if ( this.props.data.["songLinkYoutube_" + number] && this.state.pattern.test(this.props.data.["songLinkYoutube_" + number])) {
				return "Beluister op Youtube"
			} else {
				return "Niet beschikbaar"
		}
	}

	setYoutubeLink(number){
		if (this.props.data.["songLinkYoutube_" + number] === false) {
			return "#"
		} else {
			if (this.state.pattern.test(this.props.data.["songLinkYoutube_" + number])) {
				return this.props.data.["songLinkYoutube_" + number]
			} else {
				return "#"
			}
		}
	}

	setYoutubeLinkClass(number){
		if ( this.props.data.["songLinkYoutube_" + number] && this.state.pattern.test(this.props.data.["songLinkYoutube_" + number])) {
				return ""
			} else {
				return "noLink"
		}
	}

	// Spotify

	checkSpotifySongLink(number){
		if ( this.props.data.["songLinkSpotify_" + number] && this.state.pattern.test(this.props.data.["songLinkSpotify_" + number])) {
				return "Beluister op Spotify"
			} else {
				return "Niet beschikbaar"
		}
	}

	setSpotifyLink(number){
		if (this.props.data.["songLinkSpotify_" + number] === false) {
			return "#"
		} else {
			if (this.state.pattern.test(this.props.data.["songLinkSpotify_" + number])) {
				return this.props.data.["songLinkSpotify_" + number]
			} else {
				return "#"
			}
		}
	}

	setSpotifyLinkClass(number){
		if ( this.props.data.["songLinkSpotify_" + number] && this.state.pattern.test(this.props.data.["songLinkSpotify_" + number])) {
				return ""
			} else {
				return "noLink"
		}
	}

	handleClick(e){
		let overlay = e.currentTarget.parentNode;
		let songInfo = e.currentTarget.parentNode.parentNode.querySelector(".songInfo");
		let songRank = e.currentTarget.parentNode.parentNode.querySelector(".rank");
		let arrowIcon = e.currentTarget.querySelector("a.options svg.arrow");
		let childIndex = parseInt(e.currentTarget.parentNode.getAttribute("child-index"))+1;
		let elements = document.querySelectorAll("section#topSongs div.ranking ul li:not(:nth-of-type("+childIndex+")) div.overlay[open='1']");
		console.log(elements);


		for (var i = elements.length - 1; i >= 0; i--) {
			elements[i].classList.remove("open");
			elements[i].parentNode.querySelector(".songInfo").classList.remove("fadeOut");
			elements[i].parentNode.querySelector(".rank").classList.remove("fadeOut");
			elements[i].querySelector("a.options svg.arrow").classList.remove("rotate");
			elements[i].setAttribute("open", 0);
		}

		if (overlay.getAttribute("open") == 0) {
			overlay.classList.add("open");
			songInfo.classList.add("fadeOut");
			songRank.classList.add("fadeOut");
			arrowIcon.classList.add("rotate");
			overlay.setAttribute("open", 1);
			return
		}

		if (overlay.getAttribute("open") == 1) {
			overlay.classList.remove("open");
			songInfo.classList.remove("fadeOut");
			songRank.classList.remove("fadeOut");
			arrowIcon.classList.remove("rotate");
			overlay.setAttribute("open", 0);
		}
	}

	render() {
		return (
			<section id="topSongs">
					<h1>Top 5</h1>
					<div className="ranking">
						<ul>
							<li>
								<span className="rank">1</span>
								<div className="songInfo">
									<span className="songName">{this.props.data.songName_1}</span>
									<span className="artistName">{this.props.data.artist_1}</span>
								</div>
								<div className="overlay">
									<a className="options" onClick={this.handleClick}><FontAwesomeIcon icon={['fas', 'chevron-circle-left']} className="arrow" /></a>
									<a className={"options " + this.setYoutubeLinkClass(1)} title={this.checkYoutubeSongLink(1)} href={this.setYoutubeLink(1)} target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} className="youtube"/></a>
									<a className={"options " + this.setSpotifyLinkClass(1)} title={this.checkSpotifySongLink(1)} href={this.setSpotifyLink(1)} target="_blank"><FontAwesomeIcon icon={['fab', 'spotify']} className="spotify"/></a>
								</div>
							</li>

							<li>
								<span className="rank">2</span>
								<div className="songInfo">
									<span className="songName">{this.props.data.songName_2}</span>
									<span className="artistName">{this.props.data.artist_2}</span>
								</div>
								<div className="overlay">
									<a className="options" onClick={this.handleClick}><FontAwesomeIcon icon={['fas', 'chevron-circle-left']} className="arrow" /></a>
									<a className={"options " + this.setYoutubeLinkClass(2)} title={this.checkYoutubeSongLink(2)} href={this.setYoutubeLink(2)} target="_blank" ><FontAwesomeIcon icon={['fab', 'youtube']} className="youtube"/></a>
									<a className={"options " + this.setSpotifyLinkClass(2)} title={this.checkSpotifySongLink(2)} href={this.setSpotifyLink(2)} target="_blank"><FontAwesomeIcon icon={['fab', 'spotify']} className="spotify"/></a>
								</div>
							</li>

							<li>
								<span className="rank">3</span>
								<div className="songInfo">
									<span className="songName">{this.props.data.songName_3}</span>
									<span className="artistName">{this.props.data.artist_3}</span>
								</div>
								<div className="overlay">
									<a className="options" onClick={this.handleClick}><FontAwesomeIcon icon={['fas', 'chevron-circle-left']} className="arrow" /></a>
									<a className={"options " + this.setYoutubeLinkClass(3)} title={this.checkYoutubeSongLink(3)} href={this.setYoutubeLink(3)} target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} className="youtube"/></a>
									<a className={"options " + this.setSpotifyLinkClass(3)} title={this.checkSpotifySongLink(3)} href={this.setSpotifyLink(3)} target="_blank"><FontAwesomeIcon icon={['fab', 'spotify']} className="spotify"/></a>
								</div>
							</li>

							<li>
								<span className="rank">4</span>
								<div className="songInfo">
									<span className="songName">{this.props.data.songName_4}</span>
									<span className="artistName">{this.props.data.artist_4}</span>
								</div>
								<div className="overlay">
									<a className="options" onClick={this.handleClick}><FontAwesomeIcon icon={['fas', 'chevron-circle-left']} className="arrow" /></a>
									<a className={"options " + this.setYoutubeLinkClass(4)} title={this.checkYoutubeSongLink(4)} href={this.setYoutubeLink(4)} target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} className="youtube"/></a>
									<a className={"options " + this.setSpotifyLinkClass(4)} title={this.checkSpotifySongLink(4)} href={this.setSpotifyLink(4)} target="_blank"><FontAwesomeIcon icon={['fab', 'spotify']} className="spotify"/></a>
								</div>
							</li>

							<li>
								<span className="rank">5</span>
								<div className="songInfo">
									<span className="songName">{this.props.data.songName_5}</span>
									<span className="artistName">{this.props.data.artist_5}</span>
								</div>
								<div className="overlay">
									<a className="options" onClick={this.handleClick}><FontAwesomeIcon icon={['fas', 'chevron-circle-left']} className="arrow" /></a>
									<a className={"options " + this.setYoutubeLinkClass(5)} title={this.checkYoutubeSongLink(5)} href={this.setYoutubeLink(5)} target="_blank"><FontAwesomeIcon icon={['fab', 'youtube']} className="youtube"/></a>
									<a className={"options " + this.setSpotifyLinkClass(5)} title={this.checkSpotifySongLink(5)} href={this.setSpotifyLink(5)} target="_blank"><FontAwesomeIcon icon={['fab', 'spotify']} className="spotify"/></a>
								</div>
							</li>
							
						</ul>
						
					</div>
					
				</section>
		)
	}
}

export default Top5;