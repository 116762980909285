import React from 'react';
import axios from 'axios';
import Schedule from './Schedule';
import Encouragement from './Encouragement';
import Column from './Column';
import Top5 from './Top5';
import About from './About';

class Home extends React.Component {

	constructor (props) {
		super(props);
	}

	render() {
		//console.log(this.state.data)
		return (
			<React.Fragment>
				<div className="container">
					<Schedule></Schedule>
					<Encouragement data={this.props.data}></Encouragement>
				</div>
				<div className="container">
					<div id="switch">
						<Column data={this.props.data}></Column>
						<Top5 data={this.props.data}></Top5>
					</div>
				</div>
					<About></About>

			</React.Fragment>
		)
	}
}

export default Home;