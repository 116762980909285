import React from 'react';

class Schedule extends React.Component {

	componentDidMount(){
		let today = new Date().toLocaleString("en-US", {
			timeZone: "Europe/Amsterdam"
		});
		let scrollPosition = 100;

		today = new Date(today).getDay();
		
		if (today === 0) {
			today = 7;
		}

		document.querySelector("section#programma ul.scrolling-wrapper-flexbox li:nth-of-type("+ today +")").classList.add("currentDay");
		document.querySelector("section#programma ul.scrolling-wrapper-flexbox li:nth-of-type("+ today +")").scrollIntoView({behavior:"auto",block:"nearest", inline:"center"});
		setTimeout(function(){ 
			window.scrollTo(0,0);
		}, 1);
		
	}

	render() {
		return (
			<section id="programma">
				<h1>Het Programma</h1>
				<div className="whitespace"></div>
				<ul className="scrolling-wrapper-flexbox full-width">
					<li className="countryBlues">
						<span className="date">Maandag</span>
						<span className="date time">19:00 - 21:00</span>
						<span className="genre">Country / Blues</span>
					</li>

					<li className="reggae">
						<span className="date">Dinsdag</span>
						<span className="date time">19:00 - 21:00</span>
						<span className="genre">Reggae</span>
					</li>

					<li className="soul">
						<span className="date">Woensdag</span>
						<span className="date time">19:00 - 21:00</span>
						<span className="genre">Soul</span>
					</li>

					<li className="salsa">
						<span className="date">Donderdag</span>
						<span className="date time">19:00 - 21:00</span>
						<span className="genre">Salsa</span>
					</li>

					<li className="praiseParty">
						<span className="date">Vrijdag</span>
						<span className="date time">19:00 - 21:00</span>
						<span className="genre">Praise Party</span>
					</li>

					<li className="caribbean">
						<span className="date">Zaterdag</span>
						<span className="date time">19:00 - 21:00</span>
						<span className="genre">Caribbean Gospel</span>
					</li>

					<li className="worship">
						<span className="date">Zondag</span>
						<span className="date time">10:00 - 14:00</span>
						<span className="genre">Worship / Jazz</span>
					</li>
				</ul>
			</section>
		)
	}
}

export default Schedule;