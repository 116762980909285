import React from 'react';
import './styles/main.scss';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';

import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faChevronCircleLeft, faChevronDown, faChevronUp, faBars, faHeadphones } from '@fortawesome/free-solid-svg-icons';

import gsap from 'gsap';



library.add(faSpotify, faYoutube, 
			faCheckSquare, faCoffee, faChevronCircleLeft, faChevronDown, faChevronUp, faBars, faHeadphones);

class App extends React.Component {

	constructor (props) {
		super(props);

		this.state = {
			loading: false,
			data: "",
			error: ""
		}
	}

	componentDidMount(){
		const wordPressSiteUrl = "https://relentlessgraceradio.com";
		axios.get(`${wordPressSiteUrl}/?rest_route=/rgr_api/v1/data`).then(res => {
			this.setState({
				loading: true,
				data: res.data
			});
			//console.log(this.state)
		}).catch(error => this.setState({loading:false, error: error.response.data}))
	}

	render(){
		return(
			<React.Fragment>
	            <button className="menuButton">Menu</button>
	            <Header data={this.state.data}></Header>
	            <Home data={this.state.data}></Home>
	            <Footer data={this.state.data}></Footer>
	        </React.Fragment>
		);
	}
}

export default App;
