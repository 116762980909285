import React, {useEffect} from 'react';
import logo from '../assets/rgr-logo.svg'
import gsap from 'gsap';
import lottie from 'lottie-web';
import logoAnimation from '../assets/LogoAnimation.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends React.Component {

	constructor (props) {
		super(props);
	}

	openMusicPlayer(){
		window.open('https://azuraserv3.live-streams.nl/public/relentless_grace_radio','pagename','resizable,height=600,width=400');
	}

	componentDidMount(){

		gsap.from(".rgr-logo",{
			duration: 	2.4,
			opacity: 	0,
			delay: 		0
		})


		gsap.from(".rgr-logo",{
			duration: 	1.3,
			y: 			-25,
			scale: 		0.85,
			delay: 		2.5
		})

		gsap.from(["header .pageInfo h2", "header .pageInfo .Livebutton"],{
			duration: 	1.5,
			y: 			50,
			opacity: 	0,
			delay: 		2.5,
			stagger: 	0.4
		})

		const animation = lottie.loadAnimation({
			container: document.querySelector('header div.pageInfo div.rgr-logo'),
			animationData: require('../assets/LogoAnimation.json'),
			renderer: 'svg',
			loop: false,
			autoplay: true,
		})
		animation.setSpeed(1.2);
	}

	render() {

		return (
			<React.Fragment>
				<header>
				<div className="container">
					<nav className="closed">
						<ul>
							<li><a href="#Home" id="linkToHome">Home</a></li>
							<li><a href="#BibleText" id="linkToBibleText">Bemoediging</a></li>
							<li><a href="#Programma" id="linkToProgramma">Programma</a></li>
							<li><a href="#Column" id="linkToColumn">Column</a></li>
							<li><a href="#About" id="linkToAbout">About</a></li>
							<li><a href="#Contact" id="linkToContact">Contact</a></li>
						</ul>
					</nav>
				</div>

				<div className="pageInfo">
					{/*<img className="rgr-logo" src={logo} alt="" />*/}
					<div className="rgr-logo"></div>
					<h2>{this.props.data.websiteDescription}</h2>
					<a href="#" className="Livebutton" onClick={this.openMusicPlayer}><FontAwesomeIcon icon={['fas', 'headphones']} className="headphones-icon" />Luister Live</a> {/*<noscript>You need Javascript to use the previous link or use <a href="index.html" target="_blank">New Page</a></noscript>*/}
				</div>
				
				

			</header>
			<svg className="header" viewBox="0 0 4096 496" preserveAspectRatio="none">
			    {/* Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch */}
			    <desc>Created with Sketch.</desc>
			    <defs></defs>
			    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			        <path d="M4096,496 L0,496 L0,0 C0,0 960.98452,489.438753 2047.99751,489.438753 C3135.01548,489.438753 4096,0 4096,0 L4096,496 Z" id="Fill-1" fill="#FFFFFF"></path>
			    </g>
			</svg>
		</React.Fragment>
		)
	}
}

export default Header;