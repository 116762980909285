import React from 'react';

class Column extends React.Component {

	constructor(props){
		super(props);
	};

	render() {
		return (
			<section id="column">
					<h1>Column</h1>
					<div className="post" dangerouslySetInnerHTML={{__html: "<h2 className='title'>" + this.props.data.column_title + "</h2>" + this.props.data.['column content']}}>
						{/*<h2 className="title"> Genade maakt vrij </h2>
							
						<p>Het woord genade kennen wij allemaal. Wij zijn allemaal weleens onze naaste genadig geweest, wanneer ze ons hebben gekwetst. Een rechter kan een schuldige genadig zijn en hem vrijpleiten.</p>
						<p>In Christus krijgt Genade een diepere betekenis. In <strong><em>1 Joh. 4 vers 17</em></strong> wordt de liefde, die God voor ons heeft, prachtig beschreven:<strong> <em>hierin is de liefde bij ons volmaakt geworden dat wij vrijmoedigheid hebben op de dag des oordeels, want gelijk Hij is zo zijn wij in de wereld. Lees je verder wordt het nog mooier: er is in de liefde geen vrees, maar de volmaakte liefde drijft de vrees uit, want de vrees houdt verband met straf en wie vreest is niet volmaakt in de liefde.</em></strong></p>
						<p>Genade maakt dus vrij. Deze tekst gaat over Gods Genade over ons of voor ons. Wij hoeven als christenen niet te vrezen voor Gods oordeel. Nu niet en in de eeuwigheid niet. Wij zijn immers gered van Gods toorn. Vaak denken wij als christenen dat wij gered zijn van de duivel ( dat is een bonus), maar de ultieme reden staat geschreven in <strong>Rom. 5 vers 9.</strong></p>
						<p>Vaak willen wij zoveel goeds doen voor God en werken wij ons uit de naad om rechtvaardig te leven en dat is natuurlijk fantastisch, maar stiekem doen wij het toch om Hem te behagen en hopen wij op een beloning. Het rechtvaardige dat wij echter doen komt vanuit Zijn Heilige Geest die in ons woont. In <strong>Efeze 2 vers 10</strong> staat het duidelijk<em>: <strong>want Zijn maaksel zijn wij, in Christus Jezus geschapen om goede werken te doen, die God tevoren bereid heeft, opdat wij daarin zouden wandelen</strong></em></p>
						<p>Wij zijn als christenen rechtvaardig voor God door het bloed van Zijn zoon Jezus Christus. Wij moeten als christenen echt geloven dat wij gered zijn van elk oordeel en dat wij Zijn geliefde kinderen zijn. Dat is de genade, die waarlijk vrij maakt. Hoe meer wij beseffen waarvan wij vergeven en gered zijn, hoe meer wij Onze God als Onze Papa, ABBA zien en kunnen wij Hem puur liefhebben en kunnen wij onze naaste ook echt/waarachtig liefhebben. Een mooie illustratie hiervan lees je in <strong>Lukas 7 vers 47</strong>. Wanneer Jezus op visite is bij een Farizeeër komt een zondares langs en zij zalft Zijn voeten met haar tranen en haar haren. De Farizeeër oordeelt haar in zijn gedachten en Jezus heeft dat al door en zegt: <strong><em>daarom zeg ik u: haar zonden zijn haar vergeven, al waren zij vele, want zij betoonde veel liefde; maar wie weinig vergeven wordt, die betoont weinig liefde.</em></strong></p>
						<p>Wanneer wij beseffen van hoeveel wij vergeven zijn door God, hoe meer wij Zijn liefde voor ons beseffen en Hem als een echte Papa mogen ervaren en hoe meer wij ook anderen kunnen vergeven en echt liefhebben.</p>
						<p>Laten wij de Vader elke dag bidden en vragen dat Hij aan ons openbaart, wat Hij aan ons geschonken heeft door Zijn Genade, zodat wij elke dag mogen ervaren hoeveel Hij van ons houdt. Dan kunnen wij wandelen op deze aarde als vrije mensen, gekocht en betaald met het bloed van Onze Here Jezus Christus.</p>
						<p>Laten wij hiermee een zegen zijn voor een ieder die op ons pad komt, want daartoe zijn wij geroepen.</p>
						<p>&nbsp;</p>*/}

		
					</div>
				</section>
		)
	}
}

export default Column;